import React from "react";
import * as style from "../../../style.module.css";

const banner = {
  textAlign: "center",
};

const Banner = ({ title }) => {
  return (
    <>
      <div
        className=" bg-black text-white flex justify-center items-center"
        style={banner}
      >
        <div>
          <div className="p-2 m-auto w-[80%] md:w-[100%]">
            <h1
              className={`${style.elsie} text-[36px] md:text-[48px] pt-[200px] pb-[100px]`}
              style={{
                letterSpacing: ".8rem",
                color: "white",
              }}
            >
              {title}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
