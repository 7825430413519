import React from "react";
import * as style from "../styles.css";
import scarf from "../images/scarf.svg";
import ethIcon from "../images/ethIcon.png";

import { graphql, useStaticQuery } from "gatsby";
import Banner from "../components/CollectionPage/Collection-Detail/Banner";
import Header from "../components/Layout/Header";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Footer from "../components/Layout/Footer";
import { ImageWrapper } from "../shared/components/imageWrapper.styled";
import { ContentWrapper } from "../shared/components/contentWrapper.styled";

const CollectionDetailTemplate = ({ data }) => {
  const {
    collection,
    items: { nodes: items },
  } = data;

  return (
    <>
      <Header />
      <Banner title={collection.title} image={collection.image} />
      <ContentWrapper>
        <div>
          <div className="md:flex  mt-10 p-8">
            <div>
              <GatsbyImage
                className="rounded-lg"
                image={getImage(collection.image)}
                alt="..."
              />
              <div className=" my-4">
                <h1
                  className={`font-bold ${style.openSans} md:invisible`}
                  style={{ letterSpacing: "0.1em", fontSize: "30px" }}
                >
                  {collection.title}
                </h1>
              </div>
            </div>
            <div className="md:w-1/2 md:ml-10">
              <h1
                className={`font-bold ${style.openSans} hidden md:block `}
                style={{ letterSpacing: "0.12em", fontSize: "30px" }}
              >
                {collection.title}
              </h1>
              <div
                className="pt-2 lg:pt-5 text-[16px] md:text-[18px] lg:text-[20px]"
                style={{
                  lineHeight: "1.7em",
                  fontWeight: "200",
                }}
              >
                {collection.content && (
                  <p className={style.openSans}>
                    {collection?.content?.content}
                  </p>
                )}
                {!collection.content && (
                  <p className={style.openSans}>
                    {" "}
                    No content available at the moment... please check back
                    later.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {items.length > 0 && (
          <div>
            <h2
              className={`font-bold ${style.openSans} pl-5 relative text-[20px] md:text-[24px]`}
              style={{ textIndent: "20px" }}
            >
              <img
                className="mt-1 mr-2 absolute top-[4px] md:top-[7px]"
                src={scarf}
                width="15"
                alt="collection"
              />
              Items in this collection
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-4">
              {items.map((data, index) => (
                <div key={index} className="mr-4 mt-10">
                  <div className="bg-white shadow-lg rounded-lg">
                    <ImageWrapper>
                      <GatsbyImage
                        className="rounded-t-xl w-full"
                        image={getImage(data.image)}
                        alt="..."
                      />
                    </ImageWrapper>
                    <div className="mt-1 p-4 rounded-b-xl">
                      <div className={`flex justify-between ${style.poppins}`}>
                        <div className="md:flex">
                          <div>
                            <img
                              className="mt-1 mr-2"
                              src={scarf}
                              alt="collection"
                            />
                          </div>
                          <p className="text-gray-400 text-[12px] md:text-[12px] lg:text-[14px]">
                            {data.collection.title}
                          </p>
                        </div>
                        <div className="flex align-center">
                          <img
                            className="mr-2 mt-1 h-4 sm:h-3 lg:w-[12px] lg:h-[20px]"
                            src={ethIcon}
                            alt="ethIcon"
                          />
                          <p className=" sm:text-[14px] lg:text-[20px] text-[20px]">
                            {data.price}
                          </p>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="font-bold text-[20px] sm:text-[18px] lg:text-[24px]">
                          {data.title}
                        </p>
                      </div>
                      <div className="flex justify-between mt-8 align">
                        <div className="w-22 h-22 rounded-full mt-2">
                          <GatsbyImage
                            className="rounded-full"
                            image={getImage(data.marketPlaceIcon)}
                            alt="icon"
                          />
                        </div>
                        {/* <div>
                    <button
                      style={{ fontSize: "14px" }}
                      className="pl-6 pr-6 pt-2 pb-2 bg-black text-white rounded-lg"
                    >
                      Buy Now
                    </button>
                  </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ContentWrapper>

      <div className="mt-6">
        <Footer />
      </div>
    </>
  );
};

export const query = graphql`
  query GetSingleCollection($slug: String) {
    collection: contentfulCollections(slug: { eq: $slug }) {
      slug
      title
      subTitle
      image {
        gatsbyImageData(width: 400, height: 400)
      }
      content {
        content
      }
    }
    items: allContentfulItems(filter: { collection: { slug: { eq: $slug } } }) {
      nodes {
        slug
        price
        title
        collector
        collection {
          title
        }
        image {
          gatsbyImage(width: 400, height: 400)
        }
        marketPlaceIcon {
          gatsbyImage(width: 20, height: 20)
        }
      }
    }
  }
`;

export default CollectionDetailTemplate;
