import styled from "styled-components";

export const ImageWrapper = styled.div`
  & img {
    transition: transform 0.4s !important;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
`;
